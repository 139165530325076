import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import CardBody from '../../common/CardBody';
import ProductImage from '../../common/ProductImage';
import EditButton from '../../common/EditButton';
import ViewButton from '../../common/ViewButton';
import DeleteButton from '../../common/DeleteButton';
import { Form } from 'react-bootstrap';

const BlogListBox = ({
      loading,
      blogs,
      totalBlogs,
      perPages,
      setLimit,
      currentPage,
      getData,
      fetchBlogs,
      setSearch,
      setBlogs,
      setLoading
}) => {
      const token = JSON.parse(localStorage.getItem('token'));

      const handleStatus = async (category) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blogs/${category.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (blog) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blogs/${blog.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlogs((blogs) =>
                                    blogs.filter((item) => item.id !== blog.id)
                              );
                              fetchBlogs();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <CardBody
                        totalItems={totalBlogs}
                        perPages={perPages}
                        setLimit={setLimit}
                        currentPage={currentPage}
                        getData={getData}
                        setSearch={setSearch}
                  >
                        <thead>
                              <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Date</th>
                                    <th width="80px">Status</th>
                                    <th width="100px">Action</th>
                              </tr>
                        </thead>

                        <tbody>
                              {!loading ? (
                                    blogs.length > 0 ? (
                                          blogs.map((blog, index) => (
                                                <>
                                                      <tr key={index}>
                                                            <td>
                                                                  {blog.index}
                                                            </td>
                                                            <td>
                                                                  <ProductImage
                                                                        title={
                                                                              blog.title
                                                                        }
                                                                        image={
                                                                              blog.image
                                                                        }
                                                                        category={`Category: ${blog.category}`}
                                                                  />
                                                            </td>
                                                            <td>{blog.date}</td>
                                                            <td className="text-center">
                                                                  <Form.Check
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        defaultChecked={
                                                                              blog.status ===
                                                                              1
                                                                                    ? true
                                                                                    : false
                                                                        }
                                                                        onClick={() =>
                                                                              handleStatus(
                                                                                    blog
                                                                              )
                                                                        }
                                                                  />
                                                            </td>
                                                            <td className="text-center">
                                                                  <EditButton
                                                                        link={`/dashboard/blogs/${blog.slug}/edit`}
                                                                  />
                                                                  <ViewButton
                                                                        link={`/dashboard/blogs/${blog.slug}/show`}
                                                                  />
                                                                  <DeleteButton
                                                                        item={
                                                                              blog
                                                                        }
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        setLoading={
                                                                              setLoading
                                                                        }
                                                                        handleDelete={
                                                                              handleDelete
                                                                        }
                                                                  />
                                                            </td>
                                                      </tr>
                                                </>
                                          ))
                                    ) : (
                                          <>
                                                <tr>
                                                      <td
                                                            colSpan={5}
                                                            className="text-center"
                                                      >
                                                            No Blog Found
                                                      </td>
                                                </tr>
                                          </>
                                    )
                              ) : (
                                    <>
                                          <tr>
                                                <td
                                                      colSpan={5}
                                                      className="text-center"
                                                >
                                                      Loading...
                                                </td>
                                          </tr>
                                    </>
                              )}
                        </tbody>
                  </CardBody>
            </>
      );
};

export default BlogListBox;
