import React, { useState, useEffect } from "react";

import axios from "axios";
import { Card, Col, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import CardHeader from "../../components/common/CardHeader";
import LinkButton from "../../components/common/LinkButton";
import CardBody from "../../components/common/CardBody";
import EditButton from "../../components/common/EditButton";
import ViewButton from "../../components/common/ViewButton";
import DeleteButton from "../../components/common/DeleteButton";
import FormStatus from "../../components/form/FormStatus";
import ProductImage from "../../components/common/ProductImage";

const CatalogueList = () => {
      const [loading, setLoading] = useState(false);
      const [catalogues, setCatalogues] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues`,
                  {
                        headers: {
                              Authorization: `Bearer ${token}`,
                        },
                  }
            )
                  .then((response) => {
                        setCatalogues(response.data.catalogues);
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };
      useEffect(() => {
            loadData();
      }, []);

      const handleStatus = async (catalogue) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogue.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (catalogue) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogue.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCatalogues((catalogues) =>
                                    catalogues.filter(
                                          (item) => item.id !== catalogue.id
                                    )
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Catalogue List">
                                          <LinkButton
                                                link="/dashboard/catalogues/create"
                                                title="Add New"
                                          />
                                    </CardHeader>
                                    <CardBody>
                                          <thead>
                                                <tr>
                                                      <th>#</th>
                                                      <th>Title</th>
                                                      <th width="80px">
                                                            Status
                                                      </th>
                                                      <th width="120px">
                                                            Action
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      catalogues?.length ? (
                                                            catalogues.map(
                                                                  (
                                                                        catalogue,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td>
                                                                                          {
                                                                                                ++index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      catalogue.title
                                                                                                }
                                                                                                image={
                                                                                                      catalogue.image
                                                                                                }
                                                                                                category={`User: ${catalogue.user_name}`}
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          <FormStatus
                                                                                                item={
                                                                                                      catalogue
                                                                                                }
                                                                                                handleStatus={
                                                                                                      handleStatus
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <EditButton
                                                                                                link={`/dashboard/catalogues/${catalogue.slug}/edit`}
                                                                                          />
                                                                                          <ViewButton
                                                                                                link={`/dashboard/catalogues/${catalogue.slug}/show`}
                                                                                          />
                                                                                          <DeleteButton
                                                                                                loading={
                                                                                                      loading
                                                                                                }
                                                                                                setLoading={
                                                                                                      setLoading
                                                                                                }
                                                                                                item={
                                                                                                      catalogue
                                                                                                }
                                                                                                handleDelete={
                                                                                                      handleDelete
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              4
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        No
                                                                        Catalogue
                                                                        Found
                                                                  </td>
                                                            </tr>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              4
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        Loading
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default CatalogueList;
