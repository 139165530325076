import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { ItemShow } from '../../components/form';
import toast from 'react-hot-toast';
import ImageSlider from '../../components/sliders';

const AssociateShow = () => {
      const { assocaiteId } = useParams();

      const [loading, setLoading] = useState(false);
      const [associate, setAssociate] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/associates/${assocaiteId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setAssociate(response.data.associate);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col xs={12}>
                              <Card>
                                    <Card.Header>
                                          <Card.Title>
                                                Associate Detail
                                          </Card.Title>
                                          <Link
                                                to="/dashboard/associates"
                                                className="btn btn-link"
                                          >
                                                Back
                                          </Link>
                                    </Card.Header>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col md={6}>
                                                            <Card>
                                                                  <Card.Body>
                                                                        <ListGroup>
                                                                              <ItemShow
                                                                                    title="Title"
                                                                                    value={
                                                                                          associate.title
                                                                                    }
                                                                              />
                                                                              <ItemShow
                                                                                    title="User Name"
                                                                                    value={
                                                                                          associate.user_name
                                                                                    }
                                                                              />

                                                                              <ItemShow
                                                                                    title="Status"
                                                                                    value={
                                                                                          associate.status ? (
                                                                                                <>
                                                                                                      <span className="text-success">
                                                                                                            Active
                                                                                                      </span>
                                                                                                </>
                                                                                          ) : (
                                                                                                <>
                                                                                                      <span className="text-warning">
                                                                                                            Disable
                                                                                                      </span>
                                                                                                </>
                                                                                          )
                                                                                    }
                                                                              />
                                                                        </ListGroup>
                                                                  </Card.Body>
                                                            </Card>
                                                      </Col>
                                                      <Col md={6}>
                                                            <div>
                                                                  <ImageSlider
                                                                        image={
                                                                              associate.image
                                                                        }
                                                                  />
                                                            </div>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <div className="text-center my-5">
                                                      <Spinner />
                                                </div>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default AssociateShow;
