import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import ProductImage from '../../common/ProductImage';
import EditButton from '../../common/EditButton';
import ViewButton from '../../common/ViewButton';
import DeleteButton from '../../common/DeleteButton';
import { Form } from 'react-bootstrap';

const ProductListBox = ({
      loading,
      products,
      loadData,
      setProducts,
      setLoading
}) => {
      const token = JSON.parse(localStorage.getItem('token'));

      const handleStatus = async (product) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${product.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (product) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${product.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts((products) =>
                                    products.filter(
                                          (item) => item.id !== product.id
                                    )
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <thead>
                        <tr>
                              <th>#</th>
                              <th>Title</th>
                              <th>Category</th>
                              <th width="80px">Status</th>
                              <th width="120px">Action</th>
                        </tr>
                  </thead>

                  <tbody>
                        {!loading ? (
                              products?.length > 0 ? (
                                    products?.map((product, index) => (
                                          <>
                                                <tr key={index}>
                                                      <td>{product.index}</td>
                                                      <td>
                                                            <ProductImage
                                                                  title={
                                                                        product.title
                                                                  }
                                                                  image={
                                                                        product.image
                                                                  }
                                                                  category={`By: ${product.user_name}`}
                                                            />
                                                      </td>
                                                      <td>
                                                            {product.category}
                                                      </td>
                                                      <td className="text-center">
                                                            <Form.Check
                                                                  type="switch"
                                                                  defaultChecked={
                                                                        product.status ===
                                                                        1
                                                                              ? true
                                                                              : false
                                                                  }
                                                                  onClick={() =>
                                                                        handleStatus(
                                                                              product
                                                                        )
                                                                  }
                                                            />
                                                      </td>
                                                      <td className="text-center">
                                                            <EditButton
                                                                  link={`/dashboard/products/${product.slug}/edit`}
                                                            />
                                                            <ViewButton
                                                                  link={`/dashboard/products/${product.slug}/show`}
                                                            />
                                                            <DeleteButton
                                                                  item={product}
                                                                  loading={
                                                                        loading
                                                                  }
                                                                  setLoading={
                                                                        setLoading
                                                                  }
                                                                  handleDelete={
                                                                        handleDelete
                                                                  }
                                                            />
                                                      </td>
                                                </tr>
                                          </>
                                    ))
                              ) : (
                                    <>
                                          <tr>
                                                <td
                                                      colSpan={8}
                                                      className="text-center"
                                                >
                                                      No Product Found
                                                </td>
                                          </tr>
                                    </>
                              )
                        ) : (
                              <>
                                    <tr>
                                          <td
                                                colSpan={8}
                                                className="text-center"
                                          >
                                                Loading...
                                          </td>
                                    </tr>
                              </>
                        )}
                  </tbody>
            </>
      );
};

export default ProductListBox;
