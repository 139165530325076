import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';

const CategoryManage = () => {
      const [loading, setLoading] = useState(false);

      const [categories, setCategories] = useState([]);
      const [orders, setOrders] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/order/manage`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleDragStart = (e, index) => {
            e.dataTransfer.setData('index', index.toString());
      };

      const handleDragOver = async (e, order) => {
            e.preventDefault();
            setOrders(order);
      };

      const handleDrop = async (e, dropIndex) => {
            const dragIndex = parseInt(e.dataTransfer.getData('index'));
            const draggedProduct = categories[dragIndex];

            const updatedProducts = categories.filter(
                  (item, index) => index !== dragIndex
            );

            updatedProducts.splice(dropIndex, 0, draggedProduct);

            setCategories(updatedProducts);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/order/manage`,
                        {
                              categories: updatedProducts
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };
      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card className="mb-4">
                                    <CardHeader title="Category List">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/categories"
                                          />
                                    </CardHeader>
                              </Card>
                              {!loading ? (
                                    categories?.map((item, index) => (
                                          <>
                                                <div
                                                      className="draggable-row draggable-item"
                                                      key={index}
                                                      draggable="true"
                                                      onDragStart={(e) =>
                                                            handleDragStart(
                                                                  e,
                                                                  index
                                                            )
                                                      }
                                                      onDragOver={(e) =>
                                                            handleDragOver(
                                                                  e,
                                                                  categories
                                                            )
                                                      }
                                                      onDrop={(e) =>
                                                            handleDrop(e, index)
                                                      }
                                                >
                                                      {item.image && (
                                                            <div>
                                                                  <img
                                                                        src={
                                                                              item.image
                                                                        }
                                                                        alt=""
                                                                  />
                                                            </div>
                                                      )}
                                                      <div className="text-start">
                                                            <div>
                                                                  {item.title}
                                                            </div>
                                                      </div>
                                                </div>
                                          </>
                                    ))
                              ) : (
                                    <div className="text-center my-5 py-5">
                                          <Spinner />
                                    </div>
                              )}
                        </Col>
                  </Row>
            </>
      );
};

export default CategoryManage;
