import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

import $ from "jquery";
import { toast } from "react-hot-toast";
import CardHeader from "../../components/common/CardHeader";
import LinkButton from "../../components/common/LinkButton";
import {
      FormButton,
      FormDescription,
      FormImage,
      FormInput,
      FormSelect,
      FormStatusSelect,
      FormTextarea,
} from "../../components/form";

const FeatureCreate = () => {
      const [title, setTitle] = useState("");
      const [description, setDescription] = useState("");
      const [image, setImage] = useState("");
      const [imageUrl, setImageUrl] = useState("");
      const [link, setLink] = useState("");
      const [status, setStatus] = useState("");
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem("token"));

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  description: description,
                  image: image,
                  link: link,
                  status: status,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/features`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              $("form").trigger("reset");
                              setImageUrl("");
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.link) {
                                    toast.error(response.data.message.link);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Create New Feature">
                                                <LinkButton
                                                      title="Back"
                                                      link="/dashboard/features"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Link"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setLink
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormTextarea
                                                            title="Description"
                                                            required={true}
                                                            handleChange={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default FeatureCreate;
