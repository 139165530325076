import React from 'react';
import { Accordion, Row } from 'react-bootstrap';
import { FormDescription, FormImage } from '../../../components/form';

const BenefitDetails = ({
      description,
      setDescrition,
      imageUrl,
      setImageUrl,
      setImage
}) => {
      return (
            <>
                  <Accordion className="mt-4">
                        <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                    Benefit & Features
                              </Accordion.Header>
                              <Accordion.Body>
                                    <Row className="g-3">
                                          <FormDescription
                                                title="Description"
                                                setDescription={setDescrition}
                                                description={description}
                                          />
                                          <FormImage
                                                title="Image"
                                                imageUrl={imageUrl}
                                                setImage={setImage}
                                                setImageUrl={setImageUrl}
                                          />
                                    </Row>
                              </Accordion.Body>
                        </Accordion.Item>
                  </Accordion>
            </>
      );
};

export default BenefitDetails;
