import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

import { toast } from "react-hot-toast";
import Progress from "react-progress-2";
import ComplainListBox from "../../components/backend/ComplainListBox";
import CardHeader from "../../components/common/CardHeader";
import CardBody from "../../components/common/CardBody";
import LinkButton from "../../components/common/LinkButton";

const FeatureList = () => {
      const [loading, setLoading] = useState(false);
      const [concerns, setFeatures] = useState([]);
      const [totalItems, setTotalItems] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem("token"));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState("");

      const fetchComplain = async () => {
            setLoading(true);

            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/features?limit=${limit}&search=${search}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem("token")
                              )}`,
                        },
                  }
            )
                  .then((response) => {
                        setFeatures(response.data.features);
                        setTotalItems(response.data.totalItems);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            fetchComplain();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/features?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`,
                  },
            })
                  .then((response) => {
                        setFeatures(response.data.features);
                        setTotalItems(response.data.totalItems);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card className="mt-4">
                                    <CardHeader title="Feature List">
                                          <LinkButton
                                                link="/dashboard/features/create"
                                                title="Add New"
                                          />
                                          <LinkButton
                                                link="/dashboard/features/order/manage"
                                                title="Manage Order"
                                          />
                                    </CardHeader>
                                    <CardBody
                                          perPages={perPages}
                                          setLimit={setLimit}
                                          setSearch={setSearch}
                                          currentPage={currentPage}
                                          totalItems={totalItems}
                                          getData={getData}
                                    >
                                          <ComplainListBox
                                                loading={loading}
                                                type="features"
                                                setLoading={setLoading}
                                                fetchData={getData}
                                                associations={concerns}
                                                setAssociations={setFeatures}
                                          />
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default FeatureList;
