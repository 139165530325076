import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import {
      FormButton,
      FormDescription,
      FormImage,
      FormInput,
      FormSelect,
      FormSeo,
      FormStatusSelect
} from '../../components/form';
import BenefitDetails from './BenefitDetails';
import AdditionalItems from './AdditionalItems';

const ProductEdit = () => {
      const { productId } = useParams();
      const navigate = useNavigate();

      const [item, setItem] = useState('');
      const [subCategory, setSubCategory] = useState('');
      const [childCategory, setChildCategory] = useState('');

      const [title, setTitle] = useState('');
      const [description, setDescription] = useState('');
      const [category, setCategory] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [slug, setSlug] = useState('');
      const [isLoading, setIsLoading] = useState(false);

      const [catalogue, setCatalogue] = useState('');
      const [catalogueUrl, setCatalogueUrl] = useState('');

      const [benefitDescription, setBenefitDescription] = useState('');
      const [benefitImage, setBenefitImage] = useState('');
      const [benefitImageUrl, setBenefitImageUrl] = useState('');

      const [additionals, setAdditionals] = useState([
            {
                  title: '',
                  description: '',
                  image: '',
                  imageUrl: ''
            }
      ]);

      const [seoTitle, setSeoTitle] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);

      const [categories, setCategories] = useState([]);
      const [subCategories, setSubCategories] = useState([]);
      const [childCategories, setChildCategories] = useState([]);

      const loadData = async () => {
            setIsLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${productId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setSlug(response.data.product.slug);
                              setTitle(response.data.product.title);
                              setDescription(response.data.product.description);
                              setStatus(response.data.product.status);
                              setCategory(response.data.product.category_id);
                              setImageUrl(response.data.product.image);
                              setSeoTitle(response.data.product.seo_title);
                              setSeoKeyword(response.data.product.seo_keyword);
                              setSeoDescription(
                                    response.data.product.seo_description
                              );
                              if (response.data.product.catalogue) {
                                    setCatalogueUrl('/images/icons/pdf.png');
                              }
                              setBenefitDescription(
                                    response.data.product.benefit_description
                              );
                              setBenefitImageUrl(
                                    response.data.product.benefit_image
                              );
                              setAdditionals(response.data.product.additionals);
                              setItem(response.data.category);
                              setChildCategories(response.data.childCategories);
                              setChildCategory(response.data.childCategory);
                              setSubCategories(response.data.subCategories);
                              setSubCategory(response.data.subCategory);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/extra/data`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        setCategories(response.data.categories);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setIsLoading(false);
      };
      useEffect(() => {
            loadData();
      }, []);

      const loadCategory = async (value) => {
            setCategory(value);
            const data = {
                  id: value
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setSubCategories(response.data.categories);
                              setChildCategories([]);
                        }
                  });
      };

      const loadSubCategory = async (value) => {
            setCategory(value);
            const data = {
                  id: value
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setChildCategories(response.data.categories);
                        }
                  });
      };

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  slug: slug,
                  title: title,
                  description: description,
                  category: category,
                  image: image,
                  status: status,
                  seo_title: seoTitle,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription,
                  additionals: additionals,
                  benefit_description: benefitDescription,
                  benefit_image: benefitImage,
                  catalogue: catalogue
            };
            let updatedSlug = false;

            const handleUpdateSlug = () => {
                  if (updatedSlug) {
                        navigate(`/dashboard/products/${updatedSlug}/edit`);
                  }
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${productId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              updatedSlug = slug;
                              handleUpdateSlug();
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.category) {
                                    toast.error(response.data.message.category);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row className="gap-3">
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Edit Product">
                                                <LinkButton
                                                      link="/dashboard/products"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            required={true}
                                                            value={title}
                                                            classes={8}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Slug"
                                                            required={true}
                                                            value={slug}
                                                            classes={4}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Category"
                                                            classes={4}
                                                            selected={item}
                                                            loading={isLoading}
                                                            setData={
                                                                  loadCategory
                                                            }
                                                            required={false}
                                                            options={categories}
                                                      />
                                                      {subCategories.length >
                                                            0 && (
                                                            <FormSelect
                                                                  title="Sub Category"
                                                                  classes={4}
                                                                  loading={
                                                                        isLoading
                                                                  }
                                                                  selected={
                                                                        subCategory
                                                                  }
                                                                  setData={
                                                                        loadSubCategory
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  options={
                                                                        subCategories
                                                                  }
                                                            />
                                                      )}
                                                      {childCategories.length >
                                                            0 && (
                                                            <FormSelect
                                                                  title="Child Category"
                                                                  classes={4}
                                                                  loading={
                                                                        isLoading
                                                                  }
                                                                  setData={
                                                                        setCategory
                                                                  }
                                                                  selected={
                                                                        childCategory
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  options={
                                                                        childCategories
                                                                  }
                                                            />
                                                      )}
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            selected={status}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                              <Col lg={12}>
                                    <Card>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormDescription
                                                            description={
                                                                  description
                                                            }
                                                            title="Description"
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                      <FormImage
                                                            title="PDF File"
                                                            setImage={
                                                                  setCatalogue
                                                            }
                                                            setImageUrl={
                                                                  setCatalogueUrl
                                                            }
                                                            required={true}
                                                            imageUrl={
                                                                  catalogueUrl
                                                            }
                                                            imageType={true}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <FormSeo
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                          keyword={seoKeyword}
                                          description={seoDescription}
                                          title={seoTitle}
                                    />

                                    <BenefitDetails
                                          description={benefitDescription}
                                          setDescrition={setBenefitDescription}
                                          setImage={setBenefitImage}
                                          setImageUrl={setBenefitImageUrl}
                                          imageUrl={benefitImageUrl}
                                    />

                                    <AdditionalItems
                                          additionals={additionals}
                                          setAdditionals={setAdditionals}
                                          type={true}
                                          slug={productId}
                                    />

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ProductEdit;
