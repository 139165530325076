import React from 'react';
import { ListGroupItem } from 'react-bootstrap';

const ItemShow = ({ title, value }) => {
      return (
            <>
                  {(value || value === 0) && (
                        <ListGroupItem>
                              <strong>{title}</strong> :
                              <span className="ps-3">{value}</span>
                        </ListGroupItem>
                  )}
            </>
      );
};

export default ItemShow;
