import React from "react";
import ProductImage from "../../common/ProductImage";
import ViewButton from "../../common/ViewButton";
import DeleteButton from "../../common/DeleteButton";
import axios from "axios";
import toast from "react-hot-toast";
import { Form } from "react-bootstrap";
import EditButton from "../../common/EditButton";

const ComplainListBox = ({
      loading,
      setLoading,
      associations,
      setAssociations,
      fetchData,
      type,
}) => {
      const token = JSON.parse(localStorage.getItem("token"));

      const handleDelete = async (association) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/${type}/${association.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setAssociations((associations) =>
                                    associations.filter(
                                          (item) => item.id !== association.id
                                    )
                              );
                              fetchData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      const handleStatus = async (association) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/${type}/${association.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <thead>
                        <tr>
                              <th>#</th>
                              <th>Title</th>
                              {type === "associations" ? (
                                    <th>Category </th>
                              ) : null}
                              <th>Status</th>
                              <th width="120px">Action</th>
                        </tr>
                  </thead>
                  <tbody>
                        {!loading ? (
                              associations.length > 0 ? (
                                    associations.map((association, index) => (
                                          <>
                                                <tr key={index}>
                                                      <td className="text-center">
                                                            {association.index}
                                                      </td>
                                                      <td>
                                                            <ProductImage
                                                                  title={
                                                                        association.title
                                                                  }
                                                                  image={
                                                                        association.image
                                                                  }
                                                                  category={`By: ${association.user_name}`}
                                                            />
                                                      </td>
                                                      {type ===
                                                      "associations" ? (
                                                            <td>
                                                                  <ProductImage
                                                                        title={
                                                                              association.category
                                                                        }
                                                                        category={
                                                                              association.date
                                                                        }
                                                                  />
                                                            </td>
                                                      ) : null}
                                                      <td>
                                                            <Form.Check
                                                                  type="switch"
                                                                  id="custom-switch"
                                                                  defaultChecked={
                                                                        association.status ===
                                                                        1
                                                                              ? true
                                                                              : false
                                                                  }
                                                                  onClick={() =>
                                                                        handleStatus(
                                                                              association
                                                                        )
                                                                  }
                                                            />
                                                      </td>
                                                      <td className="text-center">
                                                            <EditButton
                                                                  link={`/dashboard/${type}/${association.slug}/edit`}
                                                            />
                                                            <ViewButton
                                                                  link={`/dashboard/${type}/${association.slug}/show`}
                                                            />
                                                            <DeleteButton
                                                                  item={
                                                                        association
                                                                  }
                                                                  loading={
                                                                        loading
                                                                  }
                                                                  setLoading={
                                                                        setLoading
                                                                  }
                                                                  handleDelete={
                                                                        handleDelete
                                                                  }
                                                            />
                                                      </td>
                                                </tr>
                                          </>
                                    ))
                              ) : (
                                    <tr>
                                          <td
                                                colSpan={7}
                                                className="text-center"
                                          >
                                                No Concern Found
                                          </td>
                                    </tr>
                              )
                        ) : (
                              <>
                                    <tr>
                                          <td
                                                colSpan={7}
                                                className="text-center"
                                          >
                                                Loading...
                                          </td>
                                    </tr>
                              </>
                        )}
                  </tbody>
            </>
      );
};

export default ComplainListBox;
