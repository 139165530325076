import React from 'react';

import { Jodit } from 'jodit';
import JoditReact from 'jodit-react';
import { FileUpload } from '../../common/FileUpload';
import { Col, FormLabel } from 'react-bootstrap';

const FormDescription = ({ title, description, setDescription, required }) => {
      const editorConfig = {
            readonly: false,
            autofocus: false,
            tabIndex: 1,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: 'insert_clear_html',
            placeholder: 'Write something awesome ...',
            beautyHTML: true,
            toolbarButtonSize: 'large',
            buttons: [
                  'source',
                  '|',
                  'bold',
                  'italic',
                  '|',
                  'ul',
                  'ol',
                  '|',
                  'font',
                  'fontsize',
                  'brush',
                  'paragraph',
                  '|',
                  'video',
                  'table',
                  'link',
                  '|',
                  'left',
                  'center',
                  'right',
                  'justify',
                  '|',
                  'undo',
                  'redo',
                  '|',
                  'hr',
                  'eraser',
                  'fullsize'
            ],
            extraButtons: ['uploadImage', 'codeBlock']
      };

      const uploadImageButton = () => {
            Jodit.defaultOptions.controls.uploadImage = {
                  name: 'Upload image to Cloudinary',
                  iconURL: '/images/upload.png',
                  exec: async (editor) => {
                        await imageUpload(editor);
                  }
            };
      };

      const codeBlockButton = () => {
            Jodit.defaultOptions.controls.codeBlock = {
                  name: 'Code Block',
                  iconURL: '/images/paste.png',
                  exec: (editor) => {
                        const pre =
                              editor.selection.j.createInside.element('pre');
                        pre.style =
                              'background-color:#F0F0F0; text-align:left; padding:10px';
                        pre.innerHTML = `${editor.selection.html}`;
                        editor.selection.insertNode(pre);
                  }
            };
      };

      const imageUpload = async (editor) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();

            input.onchange = async function () {
                  const imageFile = input.files[0];

                  if (!imageFile) {
                        return;
                  }

                  if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
                        return;
                  }

                  const imageInfo = await FileUpload(imageFile);
                  insertImage(editor, imageInfo.url);
            };
      };

      const insertImage = (editor, url) => {
            const image = editor.selection.j.createInside.element('img');
            image.setAttribute('src', url);
            editor.selection.insertNode(image);
      };

      const onChange = (value) => {
            setDescription(value);
      };

      const setContent = (newContent) => {
            setDescription(newContent);
      };

      uploadImageButton();
      codeBlockButton();

      return (
            <>
                  <Col lg={12}>
                        <FormLabel>
                              {title}{' '}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}
                        </FormLabel>
                        <JoditReact
                              config={editorConfig}
                              value={description}
                              onChange={(value) => onChange}
                              onBlur={setContent}
                        />
                  </Col>
            </>
      );
};

export default FormDescription;
