import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';

const ServiceShow = () => {
      const { serviceId } = useParams();
      const [service, setService] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/services/${serviceId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setService(response.data.service);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Service Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/services"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={7}>
                                                      {service?.image && (
                                                            <img
                                                                  src={`${service.image}`}
                                                                  className="w-100"
                                                            />
                                                      )}
                                                      <div className="px-3 mt-3">
                                                            <div>
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: service.description
                                                                        }}
                                                                  ></div>
                                                            </div>
                                                      </div>
                                                </Col>
                                                <Col lg={5}>
                                                      <ListGroup>
                                                      <ItemShow
                                                                  title="Title"
                                                                  value={
                                                                        service.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="By"
                                                                  value={
                                                                        service.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Created On:"
                                                                  value={
                                                                        service.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Title"
                                                                  value={
                                                                        service.seo_title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Keyword"
                                                                  value={
                                                                        service.seo_keyword
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Description"
                                                                  value={
                                                                        service.seo_description
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        service.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ServiceShow;
