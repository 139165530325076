import React from 'react';
import CheckMultiplePermission from '../../roles/CheckMultiplePermission';
import { MenuSeparator } from '../../navigation/styles';
import MenuItem from '../../metis/MenuItem';
import MenuLink from '../../metis/MenuLink';
import { ProductIcon } from '../../dashboard';

const ProductManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'product-list',
                              'product-create',
                              'product-edit',
                              'product-show',
                              'category-list',
                              'category-create',
                              'category-edit',
                              'category-show',
                              'brand-list',
                              'brand-edit',
                              'brand-create',
                              'brand-delete',
                              'service-list',
                              'service-create',
                              'service-edit',
                              'service-show',
                              'service-order-manage',
                              'industry-list',
                              'industry-create',
                              'industry-edit',
                              'industry-delete',
                              'reference-list',
                              'reference-create',
                              'reference-edit',
                              'reference-delete',
                              'reference-category-list',
                              'reference-category-edit',
                              'reference-category-create',
                              'reference-category-delete',
                              'download-list',
                              'download-create',
                              'download-edit',
                              'download-delete'
                        ]}
                  >
                        <MenuSeparator>product Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'product-list',
                                    'product-create',
                                    'product-edit',
                                    'product-show',
                                    'category-list',
                                    'category-create',
                                    'category-edit',
                                    'category-show',
                                    'category-order-manage',
                                    'brand-list',
                                    'brand-edit',
                                    'brand-create',
                                    'brand-delete',
                                    'service-list',
                                    'service-create',
                                    'service-edit',
                                    'service-show',
                                    'industry-list',
                                    'industry-create',
                                    'industry-edit',
                                    'industry-delete',
                                    'industry-order-manage'
                              ]}
                        >
                              <MenuItem
                                    title="Products Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/products',
                                          '/dashboard/products/create',
                                          '/dashboard/products/:productId/edit',
                                          '/dashboard/products/:productId/show',
                                          '/dashboard/categories',
                                          '/dashboard/categories/create',
                                          '/dashboard/categories/:categoryId/edit',
                                          '/dashboard/categories/:categoryId/show',
                                          '/dashboard/categories/order/manage',
                                          '/dashboard/brands',
                                          '/dashboard/brands/create',
                                          '/dashboard/brands/:brandId/edit',
                                          '/dashboard/brands/:brandId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'brand-list',
                                                'brand-create',
                                                'brand-edit',
                                                'brand-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Brand"
                                                link="/dashboard/brands"
                                                links={[
                                                      '/dashboard/brands',
                                                      '/dashboard/brands/create',
                                                      '/dashboard/brands/:brandId/edit',
                                                      '/dashboard/brands/:brandId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'category-list',
                                                'category-create',
                                                'category-edit',
                                                'category-show',
                                                'category-order-manage'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category"
                                                link="/dashboard/categories"
                                                links={[
                                                      '/dashboard/categories',
                                                      '/dashboard/categories/create',
                                                      '/dashboard/categories/:categoryId/edit',
                                                      '/dashboard/categories/:categoryId/show',
                                                      '/dashboard/categories/order/manage'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'product-list',
                                                'product-create',
                                                'product-edit',
                                                'product-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Product List"
                                                link="/dashboard/products"
                                                links={[
                                                      '/dashboard/products',
                                                      '/dashboard/products/create',
                                                      '/dashboard/products/:productId/edit',
                                                      '/dashboard/products/:productId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                              <MenuItem
                                    title="Service Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/services',
                                          '/dashboard/services/create',
                                          '/dashboard/services/:serviceId/edit',
                                          '/dashboard/services/:serviceId/show',
                                          '/dashboard/services/order/manage'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'service-list',
                                                'service-create',
                                                'service-edit',
                                                'service-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Service List"
                                                link="/dashboard/services"
                                                links={[
                                                      '/dashboard/services',
                                                      '/dashboard/services/create',
                                                      '/dashboard/services/:serviceId/edit',
                                                      '/dashboard/services/:serviceId/show',
                                                      '/dashboard/services/order/manage'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                              <MenuItem
                                    title="Industry Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/industries',
                                          '/dashboard/industries/create',
                                          '/dashboard/industries/:industryId/edit',
                                          '/dashboard/industries/:industryId/show',
                                          '/dashboard/industries/order/manage'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'industry-list',
                                                'industry-create',
                                                'industry-edit',
                                                'industry-show',
                                                'industry-order-manage'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Industry List"
                                                link="/dashboard/industries"
                                                links={[
                                                      '/dashboard/industries',
                                                      '/dashboard/industries/create',
                                                      '/dashboard/industries/:industryId/edit',
                                                      '/dashboard/industries/:industryId/show',
                                                      '/dashboard/industries/order/manage'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                              <MenuItem
                                    title="Reference Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/references',
                                          '/dashboard/references/create',
                                          '/dashboard/references/:referenceId/edit',
                                          '/dashboard/references/:referenceId/show',
                                          '/dashboard/reference_categories',
                                          '/dashboard/reference_categories/create',
                                          '/dashboard/reference_categories/:categoryId/edit',
                                          '/dashboard/reference_categories/:categoryId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'reference-category-list',
                                                'reference-category-edit',
                                                'reference-category-create',
                                                'reference-category-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category List"
                                                link="/dashboard/reference_categories"
                                                links={[
                                                      '/dashboard/reference_categories',
                                                      '/dashboard/reference_categories/create',
                                                      '/dashboard/reference_categories/:brandId/edit',
                                                      '/dashboard/reference_categories/:brandId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'reference-list',
                                                'reference-create',
                                                'reference-edit',
                                                'reference-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Reference List"
                                                link="/dashboard/references"
                                                links={[
                                                      '/dashboard/references',
                                                      '/dashboard/references/create',
                                                      '/dashboard/references/:referenceId/edit',
                                                      '/dashboard/references/:referenceId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                              <MenuItem
                                    title="Download Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/downloads',
                                          '/dashboard/downloads/create',
                                          '/dashboard/downloads/:downloadId/edit',
                                          '/dashboard/downloads/:downloadId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'download-list',
                                                'download-edit',
                                                'download-create',
                                                'download-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Download List"
                                                link="/dashboard/downloads"
                                                links={[
                                                      '/dashboard/downloads',
                                                      '/dashboard/downloads/create',
                                                      '/dashboard/downloads/:downloadId/edit',
                                                      '/dashboard/downloads/:downloadId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ProductManage;
