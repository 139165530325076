import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';

import Progress from 'react-progress-2';
import axios from 'axios';
import toast from 'react-hot-toast';
import CardBody from '../../components/common/CardBody';
import ProductImage from '../../components/common/ProductImage';
import DeleteButton from '../../components/common/DeleteButton';
import ViewButton from '../../components/common/ViewButton';
import EditButton from '../../components/common/EditButton';

const DownloadList = () => {
      const [loading, setLoading] = useState(false);
      const [downloads, setDownloads] = useState([]);
      const [totalDownloads, setTotalDownloads] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/downloads?limit=${limit}&search=${search}`,
                  {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  }
            )
                  .then((response) => {
                        setDownloads(response.data.downloads);
                        setTotalDownloads(response.data.totalDownloads);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/downloads?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDownloads(response.data.downloads);
                              setTotalDownloads(response.data.totalDownloads);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleStatus = async (category) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/downloads/${category.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (download) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/downloads/${download.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDownloads((downloads) =>
                                    downloads.filter(
                                          (item) => item.id !== download.id
                                    )
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Download List">
                                          <LinkButton
                                                link="/dashboard/downloads/create"
                                                title="Add New"
                                          />
                                    </CardHeader>
                                    <CardBody
                                          totalItems={totalDownloads}
                                          perPages={perPages}
                                          setLimit={setLimit}
                                          currentPage={currentPage}
                                          getData={getData}
                                          setSearch={setSearch}
                                    >
                                          <thead>
                                                <tr>
                                                      <th>#</th>
                                                      <th>Title</th>
                                                      <th>Date</th>
                                                      <th width="80px">
                                                            Status
                                                      </th>
                                                      <th width="100px">
                                                            Action
                                                      </th>
                                                </tr>
                                          </thead>

                                          <tbody>
                                                {!loading ? (
                                                      downloads.length > 0 ? (
                                                            downloads.map(
                                                                  (
                                                                        download,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td>
                                                                                          {
                                                                                                download.index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      download.title
                                                                                                }
                                                                                                category={`By: ${download.user_name}`}
                                                                                                image={
                                                                                                      download.image
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                download.date
                                                                                          }
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <Form.Check
                                                                                                type="switch"
                                                                                                id="custom-switch"
                                                                                                defaultChecked={
                                                                                                      download.status ===
                                                                                                      1
                                                                                                            ? true
                                                                                                            : false
                                                                                                }
                                                                                                onClick={() =>
                                                                                                      handleStatus(
                                                                                                            download
                                                                                                      )
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <EditButton
                                                                                                link={`/dashboard/downloads/${download.slug}/edit`}
                                                                                          />
                                                                                          <ViewButton
                                                                                                link={`/dashboard/downloads/${download.slug}/show`}
                                                                                          />
                                                                                          <DeleteButton
                                                                                                item={
                                                                                                      download
                                                                                                }
                                                                                                loading={
                                                                                                      loading
                                                                                                }
                                                                                                setLoading={
                                                                                                      setLoading
                                                                                                }
                                                                                                handleDelete={
                                                                                                      handleDelete
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    5
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Download
                                                                              Found
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              5
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        Loading...
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default DownloadList;
