import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { ItemShow } from '../../components/form';
import ImageSlider from '../../components/sliders';

const ProductShow = () => {
      const { productId } = useParams();
      const [product, setProduct] = useState([]);

      useEffect(() => {
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${productId}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`,
                              'Content-Type': 'multipart/form-data'
                        }
                  }
            ).then((response) => {
                  if (response.data.result === 'success') {
                        setProduct(response.data.product);
                  }
            });
      }, []);

      return (
            <>
                  <Row>
                        <Col xs={12}>
                              <Card>
                                    <Card.Header>
                                          <Card.Title>
                                                Product Detail
                                          </Card.Title>
                                          <Link
                                                to="/dashboard/products"
                                                className="btn btn-link"
                                          >
                                                Back
                                          </Link>
                                    </Card.Header>
                                    <Card.Body>
                                          <Row>
                                                <Col md={7}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Name"
                                                                  value={
                                                                        product.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Category"
                                                                  value={
                                                                        product.category
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="By"
                                                                  value={`${product.user_name}`}
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        product.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        product.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="SEO Title"
                                                                  value={
                                                                        product.seo_title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="SEO Keyword"
                                                                  value={`${product.seo_keyword}`}
                                                            />
                                                            <ItemShow
                                                                  title="SEO Description"
                                                                  value={
                                                                        product.seo_description
                                                                  }
                                                            />
                                                      </ListGroup>
                                                      <div className="mt-4">
                                                            <Accordion defaultActiveKey="120">
                                                                  <Accordion.Item eventKey="120">
                                                                        <Accordion.Header>
                                                                              Benefit
                                                                              &
                                                                              Feature
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                              <img
                                                                                    src={
                                                                                          product.benefit_image
                                                                                    }
                                                                                    alt=""
                                                                                    className="rounded"
                                                                                    style={{
                                                                                          height: '200px',
                                                                                          width: '200px'
                                                                                    }}
                                                                              />
                                                                              <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                          __html: product.benefit_description
                                                                                    }}
                                                                              ></div>
                                                                        </Accordion.Body>
                                                                  </Accordion.Item>
                                                                  {product
                                                                        .additionals
                                                                        ?.length >
                                                                        0 &&
                                                                        product.additionals?.map(
                                                                              (
                                                                                    item,
                                                                                    index
                                                                              ) => (
                                                                                    <Accordion.Item
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <Accordion.Header>
                                                                                                {
                                                                                                      item.title
                                                                                                }
                                                                                          </Accordion.Header>
                                                                                          <Accordion.Body>
                                                                                                <img
                                                                                                      src={
                                                                                                            item.imageUrl
                                                                                                      }
                                                                                                      alt=""
                                                                                                      className="rounded"
                                                                                                      style={{
                                                                                                            height: '200px',
                                                                                                            width: '200px'
                                                                                                      }}
                                                                                                />
                                                                                                <div
                                                                                                      dangerouslySetInnerHTML={{
                                                                                                            __html: item.description
                                                                                                      }}
                                                                                                ></div>
                                                                                          </Accordion.Body>
                                                                                    </Accordion.Item>
                                                                              )
                                                                        )}
                                                            </Accordion>
                                                      </div>
                                                </Col>
                                                <Col md={5}>
                                                      <Card>
                                                            <Card.Body>
                                                                  <div className="text-center">
                                                                        <ImageSlider
                                                                              image={
                                                                                    product.image
                                                                              }
                                                                        />
                                                                  </div>
                                                                  <p>
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: product.description
                                                                              }}
                                                                        ></div>
                                                                  </p>
                                                            </Card.Body>
                                                      </Card>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ProductShow;
